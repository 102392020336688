<template>
    <header>
        <div class="header-top">
            <div class="main">
                <div class="header-top-left">
                    <div class="header-welcome">
                        <span
                            ><a href="/" v-if="system">欢迎莅临{{ system.system_name }}</a></span
                        >

                        <a v-if="!userInfo" href="/#/login" class="login">请登录</a>

                        <a v-if="!userInfo" href="/#/register" class="register">免费注册</a>
                    </div>
                </div>
                <div class="header-top-right">
                    <ul class="flex">
                        <li v-if="userInfo">
                            <a-dropdown>
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()"> 个人中心 <a-icon type="down" /> </a>
                                <a-menu slot="overlay">
                                    <a-menu-item key="0">
                                        <a href="javascript:void(0)" @click="$router.push('/my')"> 个人中心 </a>
                                    </a-menu-item>
                                    <a-menu-divider />
                                    <a-menu-item key="3">
                                        <a href="javascript:void(0)" @click="logout">退出登录</a>
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </li>

                        <li>
                            <span style="cursor: pointer" @click="visiblePhone = true">联系我们</span>
                        </li>

                        <li>
                            <span style="cursor: pointer" @click="$router.push('/article/58')">帮助中心</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="header-bottom" v-if="!isHideHeaderBottom">
            <div class="top main">
                <a href="">
                    <div class="logo">
                        <img v-if="system" v-lazy="getUrl(system.system_logo)" alt="" />
                    </div>
                </a>

                <form>
                    <input v-model="keyword" type="text" placeholder="请输入搜索的内容" />
                    <button type="button" @click="search">搜索</button>
                </form>

                <ul class="flex">
                    <li>
                        <a href="javascript:void(0)" @click="visible = true">发布需求</a>
                    </li>
                    <li><a href="/#/PostJoin">招商发布</a></li>
                    <!-- <li><a href="/#/PostMerchant">发布供应</a></li>
          -->
                    <li>
                        <router-link to="/settled">供应商入驻</router-link>
                    </li>
                </ul>
            </div>

            <div class="bottom main">
                <ul>
                    <li class="active"><a href="">首页</a></li>
                    <li><a href="/#/purchase">供需大厅</a></li>
                    <!-- <li><a href="/#/hall">竞价大厅</a></li> -->
                    <li><a href="/#/Merchants">招商中心</a></li>
                    <li><a href="/#/article/58">帮助中心</a></li>
                </ul>
            </div>
        </div>

        <div :class="{ 'fix-header': true, hide: isHide }">
            <div class="main">
                <div class="links">
                    <div class="con">
                        <a-icon type="menu-unfold" />
                        <span>快速导航</span>
                    </div>

                    <ul class="nav">
                        <li>
                            <a href="/">首页</a>
                        </li>

                        <li>
                            <router-link to="/purchase">供需大厅</router-link>
                        </li>

                        <!-- <li>
                            <router-link to="/hall">竞价大厅</router-link>
                        </li> -->

                        <li>
                            <router-link to="/Merchants">招商中心</router-link>
                        </li>
                    </ul>
                </div>

                <form>
                    <input v-model="keyword" type="text" placeholder="请输入搜索的内容" />
                    <button type="button" @click="search()">搜索</button>
                </form>

                <div class="btns">
                    <button v-if="!userInfo" class="login" @click="$router.push('/login')">
                        <a-icon type="user" />
                        登录
                    </button>

                    <button v-if="!userInfo" class="register" @click="$router.push('/register')">
                        <a-icon type="user-add" />
                        注册
                    </button>

                    <button v-if="userInfo" class="register" @click="$router.push('/my')">
                        <a-icon type="user" />
                        个人中心
                    </button>
                </div>
            </div>
        </div>

        <a-modal :visible="visible" :footer="false" @cancel="handleCancel" class="modal">
            <div class="dd">
                <button @click="href('/PostPurchase')">发布回收信息</button>
                <button @click="href('/PostMerchant')">发布供应信息</button>
            </div>
        </a-modal>
        <a-modal :visible="visiblePhone" :footer="false" @cancel="visiblePhone = false" class="phone" :bodyStyle="{ padding: '0!important' }">
            <a-alert v-if="system" message="联系我们:" :description="system.system_phone" type="info" show-icon />
        </a-modal>
    </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'Header',
    props: ['isHideHeaderBottom'],
    data() {
        return {
            isHide: false,
            visiblePhone: false,
            visible: false,
            keyword: '',
        };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScrollBf, true);
    },
    computed: {
        ...mapState('user', ['userInfo']),
        ...mapState('system', ['system']),
    },
    methods: {
        href(url) {
            this.visible = false;
            this.$router.push(url);
        },
        handleCancel() {
            this.visible = false;
        },
        search() {
            console.log(1);
            this.$router.push({
                path: '/search',
                query: { keyword: this.keyword },
            });
        },
        ...mapActions('user', ['logOut']),
        async logout() {
            const data = await this.logOut();
            if (data.code == 200) {
                this.$message.success(data.msg);
            } else {
                return this.$message.error(data.msg);
            }
        },
        handleScrollBf() {
            let top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset);
            this.isHide = top > 200;
        },
    },
};
</script>

<style lang="less" scoped>
.phone {
    /deep/ .ant-modal-body {
        margin: 0;
    }
    /deep/ .ant-alert-info {
        border: 0;
    }
    /deep/ .ant-alert-message {
        display: inline-block;
    }
    /deep/ .ant-alert-description {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        color: #d40608;
    }
}
.modal {
    .dd {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
    }
    button {
        background-color: #000;
        color: #fff;
        padding: 15px 40px;
        border: 0;
        border-radius: 10px;
        cursor: pointer;
    }
}
.hide {
    transform: translateY(0) !important;
}
header {
    .fix-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        z-index: 999;
        background: #fff;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
        transform: translateY(-65px);
        transition: all 0.3s;
        .main {
            height: 100%;
            display: flex;
            align-items: center;
        }

        .btns {
            margin-left: 100px;
            display: flex;
            justify-content: space-between;
            .login {
                background: #ef4343;
            }
            .register {
                background: #53c3f1;
                margin-left: 20px;
            }

            button {
                height: 32px;
                line-height: 32px;
                padding: 0px 18px;
                border-radius: 5px;
                color: #fff;
                font-size: 14px;
                border: 0;
                outline: 0;
                cursor: pointer;
            }
        }
        form {
            display: flex;
            margin-left: 150px;
            width: 548px;
            border: 1px solid #d40608;
            height: 42px;
            border-radius: 3px 3px 3px 3px;
            input {
                border: 0;
                width: 448px;
                outline: 0;
                padding-left: 12px;
                color: #333;
                box-sizing: border-box;
                border-radius: 3px 0 0 3px;
            }
            button {
                width: 100px;
                height: 100%;
                border: 0;
                color: #fff;
                background: #d40608;
                cursor: pointer;
                border-radius: 0 3px 3px 0;
            }
        }
        .links {
            background: #dd3c3c;
            width: 200px;
            height: 100%;
            position: relative;
            z-index: 0;
            &:hover {
                .nav {
                    transform: translateY(0);
                }
            }
            .con {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                color: #fff;
                font-size: 18px;
                cursor: pointer;
                position: relative;
                z-index: 100;
                span {
                    margin-left: 15px;
                    position: relative;
                    top: -1px;
                }
            }
            .nav {
                position: absolute;
                top: 60px;
                width: 100%;
                background-color: #333;
                text-align: center;
                transition: all 0.3s;
                transform: translateY(-240px);
                z-index: 1;
                li {
                    height: 44px;
                    line-height: 44px;
                    border-top: 1px solid #555;
                    position: relative;
                    z-index: 2;
                    &:hover {
                        background: #dd3c3c;
                    }
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    .header-top {
        height: 35px;
        background-color: #f7f7f7;
        .main {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
        }
        .header-top-left {
            .header-welcome {
                font-size: 12px;
                color: #333;
                span {
                    margin-right: 15px;
                }
                .login {
                    color: #dd3c3c;
                    margin-right: 10px;
                }
                .register {
                    color: #333;
                }
            }
        }
        .header-top-right {
            .flex {
                li {
                    margin-left: 15px;
                }
            }
        }
    }

    .header-bottom {
        width: 100%;
        background-color: #fff;
        border-bottom: 1px #d40608 solid;
        text-align: left;
        position: relative;
        .top {
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .logo {
                width: 288px;
                height: 60px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            form {
                display: flex;
                margin-left: 150px;
                width: 548px;
                border: 1px solid #d40608;
                height: 42px;
                border-radius: 3px 3px 3px 3px;
                input {
                    border: 0;
                    width: 448px;
                    outline: 0;
                    padding-left: 12px;
                    color: #333;
                    box-sizing: border-box;
                    border-radius: 3px 0 0 3px;
                }
                button {
                    width: 100px;
                    height: 100%;
                    border: 0;
                    color: #fff;
                    background: #d40608;
                    cursor: pointer;
                    border-radius: 0 3px 3px 0;
                }
            }
            .flex {
                li {
                    a {
                        display: block;
                        width: 88px;
                        height: 38px;
                        line-height: 38px;
                        text-align: center;
                        border: 1px solid #dcdcdc;
                        background: #f4f4f4;
                        font-family: '微软雅黑';
                        color: #333;
                        font-size: 13px;
                        float: left;
                        margin-left: 10px;
                        &:hover {
                            color: #d40608;
                            background-color: #fff;
                            border: 1px solid #d40608;
                        }
                    }
                }
            }
        }
        .bottom {
            height: 42px;
            ul {
                display: flex;
                padding-left: 0;
                .active {
                    background: #dd3c3c;
                    a {
                        color: #fff;
                    }
                }
                li {
                    margin-right: 5px;
                    &:hover {
                        background: #dd3c3c;
                        a {
                            color: #fff;
                        }
                    }
                    a {
                        display: block;
                        padding: 0 20px;
                        font-size: 16px;
                        height: 42px;
                        line-height: 42px;
                        color: #484848;
                    }
                }
            }
        }
    }
}
</style>
