import axios from 'axios';
import store from '@/store';

const request = axios.create({
    baseURL: 'https://b9jn.com/api',
    // baseURL: 'http://x279.www.pusite.icu/api',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
});

request.interceptors.request.use(function (config) {
    config.headers['Authorization'] = store.state.user.token;
    return config;
});

request.interceptors.response.use(
    response => {
        return response.data;
    },
    err => {
        const response = err.response;
        const data = response.data;
        return Promise.reject(data);
    }
);

export default request;
