import request from '@/utils/request';
export const reqUserInfo = () => request.post('/getUserInfoByToken');
export const reqLogout = () => request.post('/logout');
export const reqLogin = params => {
    return request.post('/login', params);
};

export function reqRegister(params) {
    return request.post('/register', params);
}

export function reqRegisterCode(params) {
    return request.post('/send', params);
}
export const reqGetSystem = () => request.post('/getSiteInfo');

export function reqAddInfo(params) {
    return request.post('/reqAddInfo', params);
}
export function reqGetJoinById(params) {
    return request.post('/reqGetJoinById', params);
}
export function reqGetPurchaseHome() {
    return request.post('/reqGetPurchaseHome');
}
export function reqGetJoinHome() {
    return request.post('/reqGetJoinHome');
}
export function reqGetPurchaseHomeHot() {
    return request.post('/reqGetPurchaseHomeHot');
}
export function reqGetGongYinHome() {
    return request.post('/reqGetGongYinHome');
}

// 获取轮播
export const reqGetSlide = () => request.post('/getSlide');
export const reqGetHomeArticle = () => request.post('/reqGetHomeArticle');
export const reqGetPurchaseAndMHome = () => request.post('/reqGetPurchaseAndMHome');

// 获取用户是否唯一
export function reqRegisterUserNameIsUnique(params) {
    return request.post('/reqRegisterUserNameIsUnique', params);
}
// 获取 类型下面的
export function reqGetTypeList(params) {
    return request.post('/reqGetTypeList', params);
}

// 获取招商大厅
export function reqGetJoin(params) {
    return request.post('/reqGetJoin', params);
}
//获取地址

export function reqGetArea(params) {
    return request.post('/reqGetArea', params);
}

//获取竞价大厅
export function reqHall() {
    return request.post('/reqHall');
}

export function reqGetPurchase() {
    return request.post('/reqGetPurchase');
}
// 报价
export function reqOfferPrice(params) {
    return request.post('/reqOfferPrice', params);
}

export function reqSettled(params) {
    return request.post('/reqAddCompany', params);
}

export function reqTextareaImageUpload(params) {
    return request.post('/reqTextareaImageUpload', params);
}

// 上传图片组件
export function reqUploadImage(params) {
    return request.post('/uploadImage', params);
}

export function reqMessageSubmit(params) {
    return request.post('/messageSubmit', params);
}

export function reqJoinSubmit(params) {
    return request.post('/reqJoinSubmit', params);
}

export function reqPurchase(params) {
    return request.post('/reqPurchase', params);
}

export function reqTypeList() {
    return request.post('/getTypeList');
}

export function reqGetTypeListById(params) {
    return request.post('/reqGetTypeListById', params);
}

export const reqGetMessageById = id => request.post('/reqGetMessageById', id);
export const reqGetMerchantsById = id => request.post('/reqGetMerchantsById', id);
export const reqGetTypeMessage = () => request.post('/reqGetTypeMessage');
export const reqGetArticlesByType = params => request.post('/reqGetArticlesByType', params);
// 更新用户信息
export function reqUpdateUserInfo(params) {
    return request.post('/reqUpdateUserInfo', params);
}

export const reqGetMyPublish = () => request.post('/reqGetMyPublish');
export const reqDelMyPublishMessage = id => request.post('/reqDelMyPublishMessage', id);
export function reqSearch(params) {
    return request.post('/reqSearch', params);
}

export function reqGetArticleById(params) {
    return request.post('/reqGetArticleById', params);
}
