export default [
    {
        path: '/',
        name: 'Home',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Home'),
    },

    {
        path: '/type/:id',
        name: 'Type',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Type/'),
    },

    {
        path: '/chat',
        name: 'Chat',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Chat/'),
    },

    {
        path: '/search',
        name: 'Search',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Search/'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/Login/login'),
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/Login/register'),
    },

    {
        path: '/publish',
        name: 'Publish',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Publish/'),
    },

    {
        path: '/settled',
        name: 'Settled',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/Settled/'),
    },

    {
        path: '/Join/:id',
        name: 'Join',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Join/'),
    },

    {
        path: '/info/:id',
        name: 'Info',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Info/'),
    },

    {
        path: '/PostPurchase',
        name: 'PostPurchase',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/PostPurchase/'),
    },
    {
        path: '/PostMerchant',
        name: 'PostMerchant',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/PostMerchant/'),
    },

    {
        path: '/PostJoin',
        name: 'PostJoin',
        meta: {
            isHide: true,
        },
        component: () => import('@/views/PostJoin/'),
    },

    {
        path: '/article/:id',
        name: 'article',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Articles/'),
    },

    {
        path: '/bid/:id',
        name: 'bid',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Bid/index_'),
    },

    {
        path: '/purchase',
        name: 'purchase',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Purchase/'),
    },
    {
        path: '/hall',
        name: 'hall',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Hall/'),
    },
    {
        path: '/Merchants',
        name: 'Merchants',
        meta: {
            isHide: false,
        },
        component: () => import('@/views/Merchants/'),
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my'),
        children: [
            {
                //欢迎页
                path: '',
                name: 'me',
                meta: {
                    title: '我的资料',
                    keepalive: true,
                    isHide: false,
                },
                component: () => import('@/views/my/components/me.vue'),
            },
            {
                //欢迎页
                path: '/myPublish',
                name: 'myPublish',
                meta: {
                    title: '我的发布',
                    keepalive: true,
                    isHide: false,
                },
                component: () => import('@/views/my/components/myPublish.vue'),
            },
            {
                //欢迎页
                path: '/mypay',
                name: 'mypay',
                meta: {
                    title: '充值会员',
                    keepalive: true,
                    isHide: false,
                },
                component: () => import('@/views/my/components/pay.vue'),
            },
        ],
    },
];
