import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/style/style.less';
import VueLazyload from 'vue-lazyload';

import '@/style/font.css'; //引入字体

import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;
import 'animate.css';
// import '@/style/iconfont/iconfont.css';

import { getUrl } from './utils/utils';
Vue.prototype.getUrl = getUrl;
Vue.use(VueLazyload, {
    preload: 1.3, // 表示lazyload元素距离底部距离百分比
    loading: require('@/assets/loading.gif'), // 正在加载时显示的图片路径
    error: require('@/assets/404.png'), // 图片加载失败时显示的图片路径
    attempt: 5, // 图片加载失败后重试次数，默认3
});
Vue.config.productionTip = false;
Vue.use(Antd);
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
