<template>
  <div class="help">
    <ul>
      <li>
        <a href="">
          <a-icon type="wechat" />
        </a>
        <div class="img">
          <img src="@/assets/show-weixin_002.jpg" alt="" />
        </div>
      </li>

      <li>
        <a href="">
          <a-icon type="phone" />
        </a>

        <div class="phone">
          <span v-if="system">
            联系电话：<br />
            {{ system.system_phone }}
          </span>
        </div>
      </li>

      <li @click="goTopHead()">
        <a href="javascript:void(0)">
          <a-icon type="rocket" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Help",

  data() {
    return {};
  },
  computed: {
    ...mapState("system", ["system"]),
  },
  mounted() {},

  methods: {
    goTopHead() {
      document.documentElement.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="less" scoped>
.help {
  position: fixed;
  top: 45%;
  right: 0;
  width: 50px;
  height: 200px;
  ul {
    li {
      height: 50px;
      width: 50px;
      margin-bottom: 3px;
      text-align: center;
      background: #555;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #dd3c3c;
        .img {
          transform: translateX(0px);
        }
        .phone {
          transform: translateX(0px);
        }
      }
      a {
        color: #fff;
      }

      .img {
        position: absolute;
        right: 60px;
        top: 0;
        padding: 10px;
        background-color: #fff;
        transition: all 0.3s;
        transform: translateX(200px);
        z-index: 2;
        img {
          width: 80px;
          height: 80px;
        }
      }
      .phone {
        background-color: #fff;
        padding: 10px;
        position: absolute;
        right: 60px;
        top: 0;
        border-radius: 5px;
        color: #e40000;
        font-size: 18px;
        font-weight: 700;
        width: 160px;
        transition: all 0.3s;
        transform: translateX(300px);
        z-index: 2;
      }
    }
  }
}
</style>