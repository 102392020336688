<template>
  <div>
    <Header
      v-if="!$route.meta.isHideHAndF"
      :isHideHeaderBottom="$route.meta.isHide"
    />
    <a-config-provider :locale="locale">
      <router-view />
    </a-config-provider>
    <Footer v-if="!$route.meta.isHideHAndF" />
    <Help />
  </div>
</template>
<script >
import Header from "@/components/Header/";
import Footer from "@/components/Footer/";
import Help from "@/components/Help/";
import { mapActions } from "vuex";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import "dayjs/locale/zh-cn";
export default {
  name: "App",
  components: {
    Footer,
    Header,
    Help,
  },
  methods: {
    ...mapActions("types", ["getTypeList"]),
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {
    this.getTypeList();
  },
};
</script>
<style lang="less">
</style>
