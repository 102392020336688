import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './route.js';
import { reqGetSystem } from '@/api';
import store from '@/store';
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

const whiteList = ['Login', 'Home', 'Register', 'Info', 'Publish', 'Type', 'Search', 'Join', 'About', 'my', 'Chat', 'purchase', 'hall', 'PostJoin', 'PostPurchase', 'Merchants', 'bid', 'article', 'Settled', 'PostMerchant'];
router.beforeEach(async (to, _, next) => {
    const { token, userInfo } = store.state.user;
    const data = await reqGetSystem();
    if (data.code == 200) {
        document.title = data.data.system_name;
        store.state.system.system = data.data;
    }

    if (token) {
        if (to.name === 'login' || to.name === 'register') next(_.fullPath);
        if (userInfo) {
            next();
        } else {
            try {
                await store.dispatch('user/getUserInfo');
                next();
            } catch (e) {
                console.log(e.message);
                store.commit('user/resetUserInfo');
                next('/login');
            }
        }
    } else {
        if (whiteList.includes(to.name)) {
            next();
        } else next('/login');
    }
});
export default router;
